import React from "react";

type Props = {
  children?: React.ReactNode;
  classNames: string;
  hideContent: boolean;
};

export default function Card({ children, classNames, hideContent }: Props) {
  return (
    <div
      className={`m-auto min-h-full w-full max-w-[400px] rounded-lg bg-white px-6 py-12 border-primary border-t border-b-8 border-l border-r-8 ${classNames} ${
        hideContent ? `h-[400px]` : ``
      }`}
    >
      {!hideContent && children}
    </div>
  );
}
