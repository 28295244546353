import React, { useEffect, useState } from "react";
import Card from "./Card";
import QRCode from "react-qr-code";
import { usePayment } from "../context/paymentContext";
import copy from "copy-to-clipboard";
import { paymentListen } from "../api/swyft";
import { ClipLoader } from "react-spinners";

type Props = {
  goBack?: () => void;
  onCompleted?: () => void;
  step: number;
};

export default function Payment({ goBack, onCompleted, step }: Props) {
  const [states, setStates] = useState({ listen: false });
  const [timerSec, setTimerSec] = useState("00");
  const [timerMin, setTimerMin] = useState("00");
  const [timerSecRem, setTimerSecRem] = useState(20 * 60);
  const handleStateChange = (name: any, value: any) => {
    setStates({
      ...states,
      [name]: value,
    });
  };

  const payment = usePayment();

  const listenToPayment = async () => {
    try {
      const res = await paymentListen({
        address: payment?.address,
        amount: payment?.tokenAmount,
        transaction_key: payment?.transactionKey,
      });

      //console.log(res);

      if (res.transaction.status === "Completed" && onCompleted !== null) {
        onCompleted!();
      }
    } catch (e) {
      //
      //console.log(e);
    }
  };

  const startListen = async () => {
    let newSec = timerSecRem;
    var min = Math.floor(newSec / 60);
    var sec = newSec - min * 60;

    setTimerMin(`${min}`);
    setTimerSec(`${sec}`);

    if (sec < 10) {
      setTimerSec("0" + sec);
    }

    if (min < 10) {
      setTimerMin("0" + min);
    }
    //console.log(newSec - 1);
    setTimerSecRem((prevTime) => prevTime - 1);

    if (newSec % 10 === 0) {
      listenToPayment();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (states.listen) {
        startListen();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [states, timerSecRem]);

  return (
    <Card
      hideContent={step > 2}
      classNames={`top-0 left-0 right-0 absolute ${step > 2 && `blur-sm`}`}
    >
      <div className="flex flex-col items-center justify-center mb-4">
        <h1 className="text-sm text-center font-semibold mb-6">
          You are to pay {payment?.tokenAmount}
          {payment?.defaultAsset.split("-")[0].trim()} ({payment?.ngnAmount}
          {payment?.fiatAsset}) to
        </h1>
        <div className="border-secondary rounded-lg border-4 p-2">
          <QRCode
            size={120}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={payment?.address!}
            viewBox={`0 0 120 120`}
          />
        </div>
        <div className="my-4 w-full flex flex-row items-center text-sm justify-between border-primary rounded-lg border-2 p-2">
          <p>
            {payment?.address?.substring(0, 4)}......
            {payment?.address?.substring(
              payment?.address?.length - 10,
              payment?.address?.length
            )}
          </p>
          <button
            onClick={function () {
              if (
                copy(payment?.address!, {
                  debug: false,
                  message: payment?.address!,
                })
              ) {
                alert("Copied");
              }
            }}
            className="text-secondary"
          >
            Copy
          </button>
        </div>
        <p className="text-xs font-medium text-center">
          Disclaimer: This address only supports{" "}
          {payment?.defaultAsset.split("-")[1].trim()} Network. Do not try to
          send tokens outside this network into this wallet address. Also your
          wallet or exchange may subtract a fee from the amount sent on a
          withdrawal make sure you add the fee to the total amount due before
          sending your payment. Sending less or higher might result in lost of
          funds.
        </p>
      </div>
      {!states.listen ? (
        <div className="flex flex-col space-y-2">
          <button
            onClick={async function () {
              handleStateChange("listen", true);
            }}
            className="btn w-full flex items-center justify-center text-white font-semibold h-[50px]"
          >
            I have paid
          </button>
          <button
            className=" border-secondary border w-full h-10 text-secondary rounded-md font-semibold"
            onClick={function () {
              if (goBack != null) {
                goBack();
              }
            }}
          >
            Go back
          </button>
        </div>
      ) : (
        <div className="w-full flex flex-row item-center justify-center space-x-3">
          <ClipLoader color="#664ff0" size={20} />
          <h1 className=" text-sm text-primary font-semibold">
            {timerMin} min: {timerSec} secs
          </h1>
        </div>
      )}
    </Card>
  );
}
