import Logo from "./logo.svg";
import { ClipLoader } from "react-spinners";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Dropdown from "react-dropdown";
import Card from "./components/Card";

import Payment from "./components/Payment";
import { usePayment } from "./context/paymentContext";
import Success from "./components/Success";
import ErrorPage from "./components/ErrorPage";
import { formatKobo } from "./util";

function App() {
  const [states, setStates] = useState({ loading: false });
  const handleStateChange = (name: any, value: any) => {
    setStates({
      ...states,
      [name]: value,
    });
  };
  const [step, changeStep] = useState(1);

  // Assets

  const payment = usePayment();

  const validationSchema = Yup.object().shape({
    tokenAmount: Yup.string().required("Field is required"),
    ngnAmount: Yup.string().required("Field is required"),
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const handleForm = async (data: any) => {
    handleStateChange("loading", true);
    try {
      await payment?.pay(data.tokenAmount, data.ngnAmount);
      handleStateChange("loading", false);
      changeStep(2);
    } catch (e) {
      handleStateChange("loading", false);
      //console.log(e);
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="flex h-full min-h-screen w-full flex-col justify-center bg-primary bg-opacity-20 bg-contain">
      <div
        className="relative max-w-full"
        // style={{
        //   margin: "16% 1.5rem 0",
        // }}
      >
        {step === 1 && payment!.merchant && (
          <div className="flex flex-row items-center justify-center space-x-6 absolute left-0 right-0 -top-16 m-auto max-w-[300px]">
            {payment!.merchant.profileUrl != null ? (
              <div
                className="w-[80px] h-[80px] rounded-full bg-white border-2 border-primary"
                style={{
                  backgroundImage: `url('${payment!.merchant.profileUrl}')`,
                  backgroundRepeat: `no-repeat`,
                  backgroundPosition: `center center`,
                  backgroundSize: `cover`,
                }}
              ></div>
            ) : (
              <div className="w-[80px] h-[80px] rounded-full bg-white border-2 border-primary">
                <img
                  src={
                    payment!.merchant.avatar != null
                      ? `${payment!.merchant.avatar}.png`
                      : "male_avatar.png"
                  }
                  alt="Avatar"
                />
              </div>
            )}

            <div className="flex flex-col">
              {payment!.merchant.businessName && (
                <h4 className="font-semibold text-md">
                  {payment!.merchant.businessName}
                </h4>
              )}
              {payment!.merchant.firstname && (
                <h4 className="font-semibold text-xs">
                  {payment!.merchant.firstname} {payment!.merchant.lastname}
                </h4>
              )}
              {payment!.merchant.username && (
                <h4 className="font-semibold">@{payment!.merchant.username}</h4>
              )}
            </div>
          </div>
        )}

        <Card
          hideContent={
            step > 1 || payment?.hasError! || payment?.customerEmail == null
          }
          classNames={step !== 1 ? "blur-sm" : ""}
        >
          <div className="flex flex-row justify-end">
            <div className="flex flex-col items-end text-xs">
              {payment!.customerName && (
                <h4 className="font-semibold">{payment!.customerName}</h4>
              )}
              {payment!.customerEmail && (
                <p className="font-normal">{payment!.customerEmail}</p>
              )}
            </div>
          </div>
          <form
            className="flex flex-col my-5 space-y-6"
            onSubmit={handleSubmit(handleForm)}
          >
            <div className="flex flex-col">
              <h4 className="font-semibold text-sm mb-3">
                1 {payment!.defaultAsset.split("-")[0]} = {payment!.tokenRate}{" "}
                {payment!.fiatAsset.split("-")[0]}
              </h4>
              <div
                className={`flex flex-row space-y-2 !h-[50px] justify-between items-center w-full form-control ${
                  errors.tokenAmount && "!border-red-500"
                }`}
              >
                <div className="flex flex-col">
                  <label
                    htmlFor="tokenAmount"
                    className="text-xs text-primary text-opacity-50 font-bold duration-300 origin-0"
                  >
                    Amount to send
                  </label>
                  <input
                    type="text"
                    className="focus:outline-none"
                    placeholder=" "
                    {...register("tokenAmount")}
                    defaultValue={payment!.tokenAmount}
                    readOnly={
                      payment!.ngnAmount != null && payment!.ngnAmount > 0
                    }
                    onKeyUp={function () {
                      let tokenAmount = getValues("tokenAmount");
                      if (tokenAmount !== "" && tokenAmount !== 0) {
                        let formatedAmount = parseFloat(tokenAmount);
                        setValue(
                          "ngnAmount",
                          formatedAmount * payment?.tokenRate!
                        );
                      } else {
                        setValue("ngnAmount", 0);
                      }
                    }}
                  />
                </div>
                <Dropdown
                  options={payment!.options}
                  onChange={function (arg) {
                    payment?.updateDefaultAsset(arg.value);
                  }}
                  value={payment!.defaultAsset}
                  placeholder="Select an option"
                />
              </div>
              {errors.tokenAmount && (
                <p className="text-xs text-red-500">
                  {errors.tokenAmount.message?.toString()}
                </p>
              )}
            </div>
            <div className="flex flex-col">
              <div
                className={`flex flex-row space-y-2 !h-[50px] justify-between items-center w-full form-control ${
                  errors.ngnAmount && "!border-red-500"
                }`}
              >
                <div className="flex flex-col">
                  <label
                    htmlFor="ngnAmount"
                    className="text-xs text-primary text-opacity-50 font-bold duration-300 origin-0"
                  >
                    Amount to receive
                  </label>
                  <input
                    type="text"
                    className="focus:outline-none"
                    placeholder=" "
                    {...register("ngnAmount")}
                    defaultValue={payment!.ngnAmount ?? 0}
                    readOnly={
                      payment!.ngnAmount != null && payment!.ngnAmount > 0
                    }
                    onKeyUp={function () {
                      let ngnAmount = getValues("ngnAmount");
                      if (ngnAmount !== "" && ngnAmount !== 0) {
                        let formatedAmount = parseFloat(ngnAmount);
                        setValue(
                          "tokenAmount",
                          formatedAmount / payment?.tokenRate!
                        );
                      } else {
                        setValue("tokenAmount", 0);
                      }
                    }}
                  />
                </div>
                <Dropdown
                  options={payment!.fiatOptions}
                  onChange={function (arg) {
                    payment?.updateFiatAsset(arg.value);
                  }}
                  value={payment!.fiatAsset}
                  placeholder="Select an option"
                />
              </div>
              {errors.ngnAmount && (
                <p className="text-xs text-red-500">
                  {errors.ngnAmount.message?.toString()}
                </p>
              )}
            </div>

            <button
              disabled={payment?.tokenAmount === 0}
              className="btn w-full flex items-center justify-center text-white font-semibold h-[50px] disabled:cursor-not-allowed"
            >
              {states.loading ||
                (payment?.tokenAmount === 0 && (
                  <ClipLoader color="#ffffff" size={15} />
                ))}
              {payment?.tokenAmount !== 0 && `Pay`}
            </button>
          </form>
        </Card>

        {step === 2 && (
          <Payment
            step={step}
            goBack={() => changeStep(1)}
            onCompleted={() => changeStep(3)}
          />
        )}
        {step === 3 && <Success step={step} />}
        {payment?.hasError && <ErrorPage />}
      </div>
      <div className="mt-20 flex flex-row justify-center items-center space-x-3">
        <h4 className="text-md font-bold">Powered by</h4>
        <img src={Logo} alt="Swyft" width={80} />
      </div>
    </div>
  );
}

export default App;
