import axios from "axios";
import { Constant } from "../constant";
import { Buffer } from "buffer";

export async function fetchRate(asset: string, merchantId: string | null) {
  try {
    const res = await axios.post(
      `${Constant.API_URL}/getfiat`,
      {
        assetName: asset,
        merchantId: merchantId,
      },
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: `Bearer ${Buffer.from(
            `localizecryptoinafricastartfromnigeria10:${new Date().getFullYear()}:${
              new Date().getHours() + 1
            }:${new Date().getDate() + 1}`
          ).toString("base64")}`,
        },
      }
    );
    return res.data;
  } catch (e) {
    throw e;
  }
}

export async function initPayment(payload: any) {
  try {
    const res = await axios.post(
      `${Constant.API_URL}/getfiat/initiate`,
      {
        ...payload,
      },
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: `Bearer ${Buffer.from(
            `localizecryptoinafricastartfromnigeria10:${new Date().getFullYear()}:${
              new Date().getHours() + 1
            }:${new Date().getDate() + 1}`
          ).toString("base64")}`,
        },
      }
    );
    return res.data;
  } catch (e) {
    throw e;
  }
}

export async function paymentListen(payload: any) {
  try {
    const res = await axios.post(
      `${Constant.API_URL}/getfiat/event`,
      {
        ...payload,
      },
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: `Bearer ${Buffer.from(
            `localizecryptoinafricastartfromnigeria10:${new Date().getFullYear()}:${
              new Date().getHours() + 1
            }:${new Date().getDate() + 1}`
          ).toString("base64")}`,
        },
      }
    );
    return res.data;
  } catch (e) {
    throw e;
  }
}
