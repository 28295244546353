import { useState, useEffect, createContext, useContext, useId } from "react";
import { fetchRate, initPayment } from "../api/swyft";
import { roundAccurately } from "../converter";
import { formatKobo } from "../util";

type Props = {
  children?: React.ReactNode;
};

interface PaymentContextInterface {
  customerEmail: string | null;
  customerName: string | null;
  ngnAmount: number | null;
  merchantId: string | null;
  recipient: string | null;
  reference: string | null;
  callback: string | null;
  merchant: any;
  tokenRate: number;
  tokenAmount: number;
  options: any;
  fiatOptions: any;
  defaultAsset: string;
  fiatAsset: string;
  address: string | null;
  hasError: boolean;
  transactionKey: string;
  updateDefaultAsset: (e: string) => void;
  updateFiatAsset: (e: string) => void;
  pay: (token: number, fiat: number) => Promise<void>;
}

export const PaymentContext = createContext<
  PaymentContextInterface | undefined
>(undefined);

export function PaymentProvider({ children }: Props) {
  // Extract url queries
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);

  // Extract data from url
  const customerEmail = params.get("customerEmail");
  const customerName = params.get("customerName");
  const callback = params.get("callback");
  const ngnAmount = parseFloat(params.get("ngnAmount") ?? "0");
  const merchantId = params.get("merchantId");
  const recipient = params.get("recipient");
  const reference = params.get("reference");

  const isMerchant = recipient !== null || merchantId !== null;

  const isErrorFromCaller = !isMerchant || customerEmail == null;

  const transactionKey = useId();

  const [bankDetail, setBankDetail] = useState({
    accountNumber: null,
    accountName: null,
    bankCode: null,
    bankName: null,
  });

  const [tokenAmount, setTokenAmount] = useState(0);
  const [tokenRate, setTokenRate] = useState(0);
  const [hasError, setHasError] = useState(isErrorFromCaller);
  const [merchant, setMerchant] = useState<any>(null);

  const options = ["USDT-TRC20", "USDT-ERC20", "BUSD-BEP20"];
  const fiatOptions = ["NGN"];
  const [defaultAsset, setDefaultAsset] = useState(options[0]);
  const [fiatAsset, setFiatAsset] = useState(fiatOptions[0]);

  const [address, setAddress] = useState("");

  const initateData = async () => {
    try {
      const data = await fetchRate("USDT", recipient ?? merchantId);
      const formatedRate = formatKobo(data.exchange.rate);
      setMerchant(data.merchant);
      setTokenRate(formatedRate);
      setTokenAmount(roundAccurately(ngnAmount ?? 1 / formatedRate, 2) + 0.01);
    } catch (e) {
      //
      setHasError(true);
    }
  };

  const initatePayment = async (token: number, fiat: number) => {
    try {
      const data = await initPayment({
        merchant_id: merchantId ?? recipient,
        merchant_address: null,
        asset_name: defaultAsset.split("-")[0].trim(),
        asset_network: defaultAsset.split("-")[1].trim(),
        customer_email: customerEmail,
        customer_name: customerName,
        fiat_amount: fiat,
        token_amount: token,
        account_number: bankDetail.accountNumber,
        account_name: bankDetail.accountName,
        bank_name: bankDetail.bankName,
        bank_code: bankDetail.bankCode,
        transaction_key: transactionKey,
      });
      //console.log(data);
      setAddress(data.address);
    } catch (e) {
      //
      throw e;
    }
  };

  useEffect(() => {
    if ((merchantId !== null || recipient !== null) && merchant == null) {
      initateData();
    } else {
      //window.location.href = callback ?? "https://swyft.ng";
    }
    // Listen authenticated user
    // Unsubscribe auth listener on unmount
    //return () => unsubscriber()
  });

  return (
    <PaymentContext.Provider
      value={{
        customerEmail,
        customerName,
        callback,
        merchantId,
        recipient,
        ngnAmount,
        reference,
        merchant,
        tokenRate,
        tokenAmount,
        options,
        fiatOptions,
        defaultAsset,
        fiatAsset,
        address,
        hasError,
        transactionKey,
        updateDefaultAsset: (e) => setDefaultAsset(e),
        updateFiatAsset: (e) => setFiatAsset(e),
        pay: (token: number, fiat: number) => initatePayment(token, fiat),
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
}

// Custom hook that shorthands the context!
export const usePayment = () => useContext(PaymentContext);
