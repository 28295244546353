import React, { useEffect, useState } from "react";
import Card from "./Card";
import Confetti from "../confetti.svg";
import { usePayment } from "../context/paymentContext";

type Props = {
  step: number;
};

export default function Success({ step }: Props) {
  const [timerSec, setTimerSec] = useState("00");
  const [timerMin, setTimerMin] = useState("00");
  const [timerSecRem, setTimerSecRem] = useState(0.5 * 60);

  const payment = usePayment();

  const startListen = async () => {
    if (timerSecRem === 0) {
      window.location.href = payment?.callback ?? "https://swyft.ng";
    }
    let newSec = timerSecRem;
    var min = Math.floor(newSec / 60);
    var sec = newSec - min * 60;

    setTimerMin(`${min}`);
    setTimerSec(`${sec}`);

    if (sec < 10) {
      setTimerSec("0" + sec);
    }

    if (min < 10) {
      setTimerMin("0" + min);
    }

    setTimerSecRem((prevTime) => prevTime - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      startListen();
    }, 1000);

    return () => clearInterval(interval);
  }, [timerSecRem]);

  return (
    <Card hideContent={step > 3} classNames="top-16 left-0 right-0 absolute">
      <div className="flex flex-col items-center justify-center my-20">
        <div className="mb-5 flex flex-col items-center justify-center">
          <img
            src={Confetti}
            width={150}
            height={150}
            alt="Payment confirmed"
          />
          <h1 className="font-semibold text-2xl">Payment confirmed</h1>
          <p className="text-xs font-medium">
            You will be redirected in {timerMin} min: {timerSec} secs
          </p>
        </div>
        <button
          onClick={function () {
            window.location.href = payment?.callback ?? "https://swyft.ng";
          }}
          className="btn w-full flex items-center justify-center text-white font-semibold h-[50px]"
        >
          Redirect me
        </button>
      </div>
    </Card>
  );
}
