import React from "react";
import { usePayment } from "../context/paymentContext";
import Card from "./Card";

export default function ErrorPage() {
  const payment = usePayment();

  return (
    <Card hideContent={false} classNames={`top-0 left-0 right-0 absolute`}>
      <div className="w-full max-h-full mt-10 flex flex-col justify-center items-center text-center">
        <h1 className="text-5xl">😪</h1>
        {payment?.merchantId == null ||
          (payment?.recipient == null && (
            <h1 className="mt-4">Merchant not found</h1>
          ))}
        {payment?.customerEmail == null && (
          <h1 className="mt-4">Customer email is required</h1>
        )}
        {/* {payment?.hasError && (
          <h1 className="mt-4">
            Unexpect error occurred due to some circumstance.
          </h1>
        )} */}
        <button
          onClick={() => window.location.reload()}
          className="mt-4 border-secondary border w-full h-10 text-secondary rounded-md font-semibold"
        >
          Reload
        </button>
      </div>
    </Card>
  );
}
